import React from 'react'
import { Redirect } from 'react-router'

const Home = (props) => {
    
    if(props.history.location.pathname === '/'){
        return <Redirect to="/quick-help"/>
    }
    return (
        <div>
            <h1>Home</h1>
        </div>
    )
}

export default Home
