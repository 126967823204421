import React, { useState } from 'react'
import Navbar from './Navbar'
import Loader from 'react-loader-spinner'
// 1 => accepted
// 2 => complete
// 0 => pending 
const HelpStatus = () => {

    const [shouldDisplay, setShouldDisplay] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [mobile, setMobile] = useState('')
    const [error, setError] = useState('')
    const [nf, setNF] = useState(false)

    const [helpArr, setHelpArr] = useState([])

    const handleSubmit = (e) =>{
        e.preventDefault()
        setIsLoading(true)
        if(mobile === '' || mobile.length !== 10){
            setError('Please Enter Valid Mobile Number')
            setTimeout(() => {
                setError('')
            }, 2000)
            return
        }

        let formData = new FormData()
        formData.append('gethelpweball', 'gethelpweball')
        formData.append('mob', mobile)

        fetch('https://darjihelpline.com/dhlapi/webapi.php', {
            method: 'POST',
            body: formData
        }).then(res => {
            return res.json()
        }).then((data) => {
            console.log(data)
            if(data.success === 200){
                setShouldDisplay(true)
                setIsLoading(false)
                setHelpArr(data.de.reverse())
            }else{
                setError(data.message)
                setIsLoading(false)
                setNF(true)
            }
        })
        // setIsLoading(true)
        // setTimeout(() => {
        //     setShouldDisplay(true)
        //     setIsLoading(false)
        // }, 1000)
    }

    return (
        <div>
            <Navbar />
            <br></br>
            <br></br>
            <br></br>
            <section className="section-content padding-y">
                {
                    !shouldDisplay &&
                    <div className="mx-3 card bg-white py-3 mx-md-auto" style={{maxWidth: 520, marginTop: 40}}>
                        <article className="card-body">
                            <header className="mb-4"><h4 className="card-title">Check Help Status</h4></header>
                                <h6 style={{color: 'red'}} className="my-3">{error}</h6>
                                <form>
                                    <div className="form-row">
                                        <div className="col form-group">
                                            <label>Mobile Number</label>
                                            <input onChange={(e) => setMobile(e.target.value)} value={mobile} type="number" className="form-control" placeholder="Enter Your Mobile Number" />
                                        </div> 
                                    </div>     
                                    <div className="form-group">
                                        {!isLoading && <button onClick={handleSubmit} style={{backgroundColor: '#F8C400', color: 'white'}} type="submit" className="btn mx-auto btn-block"> Check Status  </button>}
                                        {isLoading && <div style={{marginLeft: '35%'}}>
                                            <Loader type="Oval" height={50} color="#F8C400"/>
                                        </div> }
                                    </div>    
                                </form>
                        </article>
                    </div> 
                }
                <br></br><br></br>

                {/* card start */}
                {
                    shouldDisplay && 
                    <div style={{marginLeft: 40, marginRight: 40}}>
                        <div className="col-md-9 bg-white mx-auto py-3">
                            <h2 className="text-center">Your Recent Helps</h2>
                        </div>
                    </div>
                }
                {
                    nf && 
                    <div style={{marginLeft: 40, marginRight: 40}}>
                        <div className="col-md-9 bg-white mx-auto py-3">
                            <h2 className="text-center">{error}</h2>
                        </div>
                    </div>
                }
                {
                    shouldDisplay &&
                    <div className="row col-md-9 mx-auto">
                        {
                            helpArr.length > 0 &&
                            helpArr.map((help, i) => {
                                return(
                                    <div key={i} className="col-md-6 my-3">
                                        <div className="card bg-white">
                                            <div className="card-body">
                                                <h4 className="card-title">Your Help Details {i === 0 && <div><br></br><h4 style={{color: 'green'}}>Latest</h4></div>}</h4>
                                                <hr></hr>
                                                <p className="card-text h6">Help Number: {help.id}</p>
                                                <br></br>
                                                <p className="card-text h6">Your Name: {help.fullname}</p>
                                                <br></br>
                                                <p className="card-text h6">Help Date: {help.datehelp}</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Mode: {help.helpmode}</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Type: {help.typehelp} ({help.subhelp})</p>   
                                                <br></br>
                                                <p className="card-text h6">Help State: {help.state}</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Location: {help.dist} ({help.city})</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Details: <br></br><br></br> {help.hdisc}</p>
                                            </div>
                                            <div className="card-body">
                                                <h4 className="card-title">Volunteer Details</h4>
                                                <hr></hr>
                                                <p style={{color: (help.status === '0' || help.status === '1') ? 'red' : 'green' }} className="card-text h6">Help Status: {(help.status === '0' || help.status === '1') ? 'Pending' : 'Completed'}</p>
                                                <br></br>
                                                <p className="card-text h6">Volunteer Name: {help.helper}</p>
                                                <br></br>
                                                <p className="card-text h6">Volunteer's Mobile: {help.hmob}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </section>
        </div>
    )
}

export default HelpStatus
