import { useEffect, useState } from 'react'
import '../HelperCSS.css'
import getHelpCategories from './HelpCategories'
// import { allStates, allStateWithCities } from '../helpers/helpers'
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom'

let stateObject = {
    "Andhra Pradesh": [
        "Adilabad",
        "Anantapur",
        "Chittoor",
        "Kakinada",
        "Guntur",
        "Hyderabad",
        "Karimnagar",
        "Khammam",
        "Krishna",
        "Kurnool",
        "Mahbubnagar",
        "Medak",
        "Nalgonda",
        "Nizamabad",
        "Ongole",
        "Hyderabad",
        "Srikakulam",
        "Nellore",
        "Visakhapatnam",
        "Vizianagaram",
        "Warangal",
        "Eluru",
        "Kadapa",
        "other"
    ],
    "Arunachal Pradesh": [
        "Anjaw",
        "Changlang",
        "East Siang",
        "Kurung Kumey",
        "Lohit",
        "Lower Dibang Valley",
        "Lower Subansiri",
        "Papum Pare",
        "Tawang",
        "Tirap",
        "Dibang Valley",
        "Upper Siang",
        "Upper Subansiri",
        "West Kameng",
        "West Siang",
        "other"
    ],
    "Assam": [
        "Baksa",
        "Barpeta",
        "Bongaigaon",
        "Cachar",
        "Chirang",
        "Darrang",
        "Dhemaji",
        "Dima Hasao",
        "Dhubri",
        "Dibrugarh",
        "Goalpara",
        "Golaghat",
        "Hailakandi",
        "Jorhat",
        "Kamrup",
        "Kamrup Metropolitan",
        "Karbi Anglong",
        "Karimganj",
        "Kokrajhar",
        "Lakhimpur",
        "Marigaon",
        "Nagaon",
        "Nalbari",
        "Sibsagar",
        "Sonitpur",
        "Tinsukia",
        "Udalguri",
        "other"
    ],
    "Bihar": [
        "Araria",
        "Arwal",
        "Aurangabad",
        "Banka",
        "Begusarai",
        "Bhagalpur",
        "Bhojpur",
        "Buxar",
        "Darbhanga",
        "East Champaran",
        "Gaya",
        "Gopalganj",
        "Jamui",
        "Jehanabad",
        "Kaimur",
        "Katihar",
        "Khagaria",
        "Kishanganj",
        "Lakhisarai",
        "Madhepura",
        "Madhubani",
        "Munger",
        "Muzaffarpur",
        "Nalanda",
        "Nawada",
        "Patna",
        "Purnia",
        "Rohtas",
        "Saharsa",
        "Samastipur",
        "Saran",
        "Sheikhpura",
        "Sheohar",
        "Sitamarhi",
        "Siwan",
        "Supaul",
        "Vaishali",
        "West Champaran",
        "Chandigarh","other"
    ],
    "Chhattisgarh": [
        "Bastar",
        "Bijapur",
        "Bilaspur",
        "Dantewada",
        "Dhamtari",
        "Durg",
        "Jashpur",
        "Janjgir-Champa",
        "Korba",
        "Koriya",
        "Kanker",
        "Kabirdham (Kawardha)",
        "Mahasamund",
        "Narayanpur",
        "Raigarh",
        "Rajnandgaon",
        "Raipur",
        "Surguja","other"       
    ],
    "Dadra and Nagar Haveli": [
        "Dadra and Nagar Haveli",
        "other"
    ],
    "Daman and Diu": [
        "Daman",
        "Diu",
        "other"
    ],
    "Delhi": [
        "Central Delhi",
        "East Delhi",
        "New Delhi",
        "North Delhi",
        "North East Delhi",
        "North West Delhi",
        "South Delhi",
        "South West Delhi",
        "West Delhi",
        "other"        
    ],
    "Goa": [
        "North Goa",
        "South Goa",
        "other"
    ],
    "Gujarat": [
        "Ahmedabad",
        "Amreli district",
        "Anand",
        "Botad",
        "Banaskantha",
        "Bharuch",
        "Bhavnagar",
        "Botad",
        "Chhota Udepur",
        "Dahod",
        "The Dangs",
        "Gandhinagar",
        "Gondal",
        "Jamnagar",
        "Junagadh",
        "Kutch",
        "Kheda",
        "Mehsana",
        "Narmada",
        "Navsari",
        "Patan",
        "Panchmahal",
        "Porbandar",
        "Rajkot",
        "Sabarkantha",
        "Surendranagar",
        "Surat",
        "Vyara",
        "Vadodara",
        "Valsad",
        "other"
    ],
    "Haryana": [
        "Ambala",
        "Bhiwani",
        "Charkhi Dadri",
        "Faridabad",
        "Fatehabad",
        "Gurgaon",
        "Hissar",
        "Jhajjar",
        "Jind",
        "Karnal",
        "Kaithal",
        "Kurukshetra",
        "Mahendragarh",
        "Mewat",
        "Palwal",
        "Panchkula",
        "Panipat",
        "Rewari",
        "Rohtak",
        "Sirsa",
        "Sonipat",
        "Yamuna Nagar",
        "other"
    ],
    "Himachal Pradesh": [
        "Bilaspur",
        "Chamba",
        "Hamirpur",
        "Kangra",
        "Kinnaur",
        "Kullu",
        "Lahaul and Spiti",
        "Mandi",
        "Shimla",
        "Sirmaur",
        "Solan",
        "Una",
        "other"
    ],
    "Jammu and Kashmir": [
        "Anantnag",
        "Badgam",
        "Bandipora",
        "Baramulla",
        "Doda",
        "Ganderbal",
        "Jammu",
        "Kargil",
        "Kathua",
        "Kishtwar",
        "Kupwara",
        "Kulgam",
        "Leh",
        "Poonch",
        "Pulwama",
        "Rajauri",
        "Ramban",
        "Reasi",
        "Samba",
        "Shopian",
        "Srinagar",
        "Udhampur",
        "other"
    ],
    "Jharkhand": [
        "Bokaro",
        "Chatra",
        "Deoghar",
        "Dhanbad",
        "Dumka",
        "East Singhbhum",
        "Garhwa",
        "Giridih",
        "Godda",
        "Gumla",
        "Hazaribag",
        "Jamtara",
        "Khunti",
        "Koderma",
        "Latehar",
        "Lohardaga",
        "Pakur",
        "Palamu",
        "Ramgarh",
        "Ranchi",
        "Sahibganj",
        "Seraikela Kharsawan",
        "Simdega",
        "West Singhbhum",
        "other"
    ],
    "Karnataka": [
        "Bagalkot",
        "Bangalore Rural",
        "Bangalore Urban",
        "Belgaum",
        "Bellary",
        "Bidar",
        "Bijapur",
        "Chamarajnagar",
        "Chikkamagaluru",
        "Chikkaballapur",
        "Chitradurga",
        "Davanagere",
        "Dharwad",
        "Dakshina Kannada",
        "Gadag",
        "Gulbarga",
        "Hassan",
        "Haveri district",
        "Kodagu",
        "Kolar",
        "Koppal",
        "Mandya",
        "Mysore",
        "Raichur",
        "Shimoga",
        "Tumkur",
        "Udupi",
        "Uttara Kannada",
        "Ramanagara",
        "Yadgir",
        "other"
    ],
    "Kerala": [
        "Alappuzha",
        "Ernakulam",
        "Idukki",
        "Kannur",
        "Kasaragod",
        "Kollam",
        "Kottayam",
        "Kozhikode",
        "Malappuram",
        "Palakkad",
        "Pathanamthitta",
        "Thrissur",
        "Thiruvananthapuram",
        "Wayanad",
        "other"
    ],
    "Madhya Pradesh": [
        "Alirajpur",
        "Anuppur",
        "Ashok Nagar",
        "Balaghat",
        "Barwani",
        "Betul",
        "Bhind",
        "Bhopal",
        "Burhanpur",
        "Chhatarpur",
        "Chhindwara",
        "Damoh",
        "Datia",
        "Dewas",
        "Dhar",
        "Dindori",
        "Guna",
        "Gwalior",
        "Harda",
        "Hoshangabad",
        "Indore",
        "Jabalpur",
        "Jhabua",
        "Katni",
        "Khandwa (East Nimar)",
        "Khargone (West Nimar)",
        "Mandla",
        "Mandsaur",
        "Morena",
        "Narsinghpur",
        "Neemuch",
        "Panna",
        "Rewa",
        "Rajgarh",
        "Ratlam",
        "Raisen",
        "Sagar",
        "Satna",
        "Sehore",
        "Seoni",
        "Shahdol",
        "Shajapur",
        "Sheopur",
        "Shivpuri",
        "Sidhi",
        "Singrauli",
        "Tikamgarh",
        "Ujjain",
        "Umaria",
        "Vidisha",
        "other"
    ],
    "Maharashtra": [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Bhandara",
        "Beed",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai City",
        "Mumbai suburban",
        "Nandurbar",
        "Nanded",
        "Nagpur",
        "Nashik",
        "Osmanabad",
        "Parbhani",
        "Pune",
        "Raigad",
        "Ratnagiri",
        "Sindhudurg",
        "Sangli",
        "Solapur",
        "Satara",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal",
        "other"
    ],
    "Manipur": [
        "Bishnupur",
        "Churachandpur",
        "Chandel",
        "Imphal East",
        "Senapati",
        "Tamenglong",
        "Thoubal",
        "Ukhrul",
        "Imphal West",
        "other"
    ],
    "Meghalaya": [
        "East Garo Hills",
        "East Khasi Hills",
        "Jaintia Hills",
        "Ri Bhoi",
        "South Garo Hills",
        "West Garo Hills",
        "West Khasi Hills",
        "other"
    ],
    "Mizoram": [
        "Dimapur",
        "Kohima",
        "Mokokchung",
        "Mon",
        "Phek",
        "Tuensang",
        "Wokha",
        "Zunheboto",
        "other"
    ],
    "Orissa": [
        "Angul",
        "Boudh (Bauda)",
        "Bhadrak",
        "Balangir",
        "Bargarh (Baragarh)",
        "Balasore",
        "Cuttack",
        "Debagarh (Deogarh)",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghpur",
        "Khordha",
        "Kendujhar (Keonjhar)",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur (Sonepur)",
        "Sundergarh",
        "other"
    ],
    "Pondicherry": [
        "Karaikal",
        "Mahe",
        "Pondicherry",
        "Yanam",
        "other"
    ],
    "Punjab": [
        "Amritsar",
        "Barnala",
        "Bathinda",
        "Firozpur",
        "Faridkot",
        "Fatehgarh Sahib",
        "Fazilka",
        "Gurdaspur",
        "Hoshiarpur",
        "Jalandhar",
        "Kapurthala",
        "Ludhiana",
        "Mansa",
        "Moga",
        "Sri Muktsar Sahib",
        "Pathankot",
        "Patiala",
        "Rupnagar",
        "Ajitgarh (Mohali)",
        "Sangrur",
        "Nawanshahr",
        "Tarn Taran",
        "other"
    ],
    "Rajasthan": [
        "Ajmer",
        "Alwar",
        "Bikaner",
        "Barmer",
        "Banswara",
        "Bharatpur",
        "Baran",
        "Bundi",
        "Bhilwara",
        "Churu",
        "Chittorgarh",
        "Dausa",
        "Dholpur",
        "Dungapur",
        "Ganganagar",
        "Hanumangarh",
        "Jhunjhunu",
        "Jalore",
        "Jodhpur",
        "Jaipur",
        "Jaisalmer",
        "Jhalawar",
        "Karauli",
        "Kota",
        "Nagaur",
        "Pali",
        "Pratapgarh",
        "Rajsamand",
        "Sikar",
        "Sawai Madhopur",
        "Sirohi",
        "Tonk",
        "Udaipur",
        "other"
    ],
    "Sikkim": [
        "East Sikkim",
        "North Sikkim",
        "South Sikkim",
        "West Sikkim",
        "other"
    ],
    "Tamil Nadu": [
        "Ariyalur",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Madurai",
        "Nagapattinam",
        "Nilgiris",
        "Namakkal",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Salem",
        "Sivaganga",
        "Tirupur",
        "Tiruchirappalli",
        "Theni",
        "Tirunelveli",
        "Thanjavur",
        "Thoothukudi",
        "Tiruvallur",
        "Tiruvarur",
        "Tiruvannamalai",
        "Vellore",
        "Viluppuram",
        "Virudhunagar",
        "other"
    ],
    "Telangana": [
        "Adilabad",
        "Bhadradri Kothagudem",
        "Hyderabad",
        "Jagtial",
        "Jangaon",
        "Jayashankar Bhoopalpally",
        "Jogulamba Gadwal",
        "Kamareddy",
        "Karimnagar",
        "Khammam",
        "Komaram Bheem Asifabad",
        "Mahabubabad",
        "Mahabubnagar",
        "Mancherial",
        "Medak",
        "Medchal",
        "Nagarkurnool",
        "Nalgonda",
        "Nirmal",
        "Nizamabad",
        "Peddapalli",
        "Rajanna Sircilla",
        "Rangareddy",
        "Sangareddy",
        "Siddipet",
        "Suryapet",
        "Vikarabad",
        "Wanaparthy",
        "Warangal",
        "Yadadri Bhuvanagiri"
    ],
    "Tripura": [
        "Dhalai",
        "North Tripura",
        "South Tripura",
        "Khowai",
        "West Tripura",
        "other"
    ],
    "Uttar Pradesh": [
        "Agra",
        "Allahabad",
        "Aligarh",
        "Ambedkar Nagar",
        "Auraiya",
        "Azamgarh",
        "Barabanki",
        "Budaun",
        "Bagpat",
        "Bahraich",
        "Bijnor",
        "Ballia",
        "Banda",
        "Balrampur",
        "Bareilly",
        "Basti",
        "Bulandshahr",
        "Chandauli",
        "Chhatrapati Shahuji Maharaj Nagar",
        "Chitrakoot",
        "Deoria",
        "Etah",
        "Kanshi Ram Nagar",
        "Etawah",
        "Firozabad",
        "Farrukhabad",
        "Fatehpur",
        "Faizabad",
        "Gautam Buddh Nagar",
        "Gonda",
        "Ghazipur",
        "Gorakhpur",
        "Ghaziabad",
        "Hamirpur",
        "Hardoi",
        "Mahamaya Nagar",
        "Jhansi",
        "Jalaun",
        "Jyotiba Phule Nagar",
        "Jaunpur district",
        "Ramabai Nagar (Kanpur Dehat)",
        "Kannauj",
        "Kanpur",
        "Kaushambi",
        "Kushinagar",
        "Lalitpur",
        "Lakhimpur Kheri",
        "Lucknow",
        "Mau",
        "Meerut",
        "Maharajganj",
        "Mahoba",
        "Mirzapur",
        "Moradabad",
        "Mainpuri",
        "Mathura",
        "Muzaffarnagar",
        "Panchsheel Nagar district (Hapur)",
        "Pilibhit",
        "Shamli",
        "Pratapgarh",
        "Rampur",
        "Raebareli",
        "Saharanpur",
        "Sitapur",
        "Shahjahanpur",
        "Sant Kabir Nagar",
        "Siddharthnagar",
        "Sonbhadra",
        "Sant Ravidas Nagar",
        "Sultanpur",
        "Shravasti",
        "Unnao",
        "Varanasi",
        "other"
    ],
    "Uttarakhand": [
        "Almora",
        "Bageshwar",
        "Chamoli",
        "Champawat",
        "Dehradun",
        "Haridwar",
        "Nainital",
        "Pauri Garhwal",
        "Pithoragarh",
        "Rudraprayag",
        "Tehri Garhwal",
        "Udham Singh Nagar",
        "Uttarkashi",
        "other"
    ],
    "West Bengal": [
        "Birbhum",
        "Bankura",
        "Bardhaman",
        "Darjeeling",
        "Dakshin Dinajpur",
        "Hooghly",
        "Howrah",
        "Jalpaiguri",
        "Cooch Behar",
        "Kolkata",
        "Maldah",
        "Paschim Medinipur",
        "Purba Medinipur",
        "Murshidabad",
        "Nadia",
        "North 24 Parganas",
        "South 24 Parganas",
        "Purulia",
        "Uttar Dinajpur",
        "other"
    ]
}

const HelpForm = () => {

    const [subTypesOfHelp, setSubTypesOfHelp] = useState([]) 
    const [cityOfDist, setCityOfDist] = useState([]) 
    const [allStates, setAllStates] = useState([])
    const [helpDetails, setHelpDetails] = useState({
        fullname: '',
        mobile: '',
        email: '',
        description: '',
        address: '',
        pincode: '',
        state: '',
        district: '',
        city: '',
        typeofhelp: '',
        subtypeofhelp: '',
        time: ''
    })
    const [error, setError] = useState({})
    const [cities, setCities] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [shouldFormDisplay, setShouldFormDisplay] = useState(true)

    useEffect(() => {
        setAllStates(['Choose...', ...Object.keys(stateObject)])
    }, [])

    const onHelpTypeChange = (e) =>{
        let selectedType = e.target.value
        if(selectedType === 'Choose...'){
            return
        }
        let helpCategories = getHelpCategories(selectedType)
        let arr = ['Choose...', ...helpCategories]
        setHelpDetails({...helpDetails, typeofhelp: e.target.value})
        setSubTypesOfHelp(arr)
    }

    const onStateChange = (e) => {
        let selectedState = e.target.value
        let filteredCities = stateObject[selectedState]
        console.log(filteredCities, 'cities')
        setHelpDetails({...helpDetails, state: selectedState})
        setCities(['Choose...', ...filteredCities])
    }

    // console.log(helpDetails, error)
    // console.log(cities)

    const onChangeDist = (e) => {
        setCityOfDist([])
        // setHelpDetails({...helpDetails, district: e.target.value})
        
        // console.log('called dd')
        let formData = new FormData()
        formData.append('getcity', 'getcity')
        formData.append('state', helpDetails.state)
        formData.append('dist', e.target.value)

        fetch('https://darjihelpline.com/dhlapi/RegistrationControllerJson.php', {
                method: 'POST',
                body: formData
            }).then(res => {
                return res.json()
            }).then((data) => {
                // console.log(data)
                if(data.success === 200){
                    if(data.de.length > 0){
                        setCityOfDist([{city: 'Choose...'}, ...data.de])
                        console.log('success fully added')
                        setHelpDetails({...helpDetails, district: e.target.value})
                    }
                }else{
                    console.log('dsfsdf')
                    setCityOfDist([{city: 'No Needed'}])
                    setHelpDetails({...helpDetails, city: 'No Needed', district: e.target.value})
                }
            })
    }

    const handleInputChange = (e, inputName) =>{
        if(inputName === "mobile" && e.target.value.length > 10){
            return
        }
        setHelpDetails({...helpDetails, [inputName]: e.target.value})
    }

    // console.log(error)
    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
        console.log(helpDetails, error)

        if(helpDetails.mobile.length !== 10){
            setError({...error, mobile: true})
            return
        }

        const newErrorInstance = {}
        for (const key in helpDetails) {
            if(helpDetails[key] === '' || helpDetails[key] === "Choose..."){
                newErrorInstance[key] = true
            }else{
                newErrorInstance[key] = false
            }
        }
        let checker = Object.values(newErrorInstance).filter(x => x)
        if(checker.length > 0){
            console.log('error in', Object.keys(newErrorInstance))
            setError(newErrorInstance)
            setLoading(false)
            return
        }else{
            console.log('in')

            const todayDate = new Date()
            const date = `${todayDate.getDate()}-${todayDate.getMonth() + 1}-${todayDate.getFullYear()}`
            
            let formData = new FormData()
            formData.append('helpadd', 'helpadd')
            formData.append('fullname', helpDetails.fullname)
            formData.append('mobileno', helpDetails.mobile)
            formData.append('email', helpDetails.email)
            formData.append('address', helpDetails.address)
            formData.append('pincode', helpDetails.pincode)
            formData.append('state', helpDetails.state)
            formData.append('dist', helpDetails.district)
            formData.append('typehelp', helpDetails.typeofhelp)
            formData.append('helpmode', helpDetails.time)
            formData.append('datehelp', date)
            formData.append('status', '0')
            formData.append('helper', 'Request Under Processing')
            formData.append('hmob', 'Request Under Processing')
            formData.append('hdisc', helpDetails.description)
            formData.append('uid', 'webapi')
            formData.append('subhelp', helpDetails.subtypeofhelp)

            if(helpDetails.city === "No Needed"){
                formData.append('city', '')
            }else{
                formData.append('city', helpDetails.city)
            }

            fetch('https://darjihelpline.com/dhlapi/webapi.php', {
                method: 'POST',
                body: formData
            }).then(res => {
                return res.json()
            }).then((data) => {
                console.log(data)
                if(data.success === 200){
                    console.log('success fully added')
                    setLoading(false)
                    setShouldFormDisplay(false)
                }else{
                    console.log('locha')
                    setLoading(false)
                }
            })
        }
    }

    const getError = (key) =>{
        if(error[key] === true){
            return `${key} is required`
        }
    }

    return (
        <section className="section-content padding-y">
            <div className="mx-3 card bg-white py-3 mx-md-auto" style={{maxWidth: 520, marginTop: 40}}>
                <article className="card-body">
                    <header className="mb-4"><h4 className="card-title text-center">{shouldFormDisplay ? 'Ask Quick Help' : 'Help Added Successfully'}</h4></header>
                        {
                            shouldFormDisplay &&
                            <form>
                                <div className="form-row">
                                    <div className="col form-group">
                                        <label>Full Name</label>
                                        <input onChange={(e) => handleInputChange(e, 'fullname')} type="text" className="form-control" placeholder="Enter Your Full Name" value={helpDetails.fullname}/>
                                        <small style={{color: 'red'}} className="form-text">{getError('fullname')}</small>
                                    </div> 
                                    
                                </div>
                                <div className="form-group">
                                    <label>Mobile</label>
                                    <input onChange={(e) => handleInputChange(e, 'mobile')} type="number" className="form-control" placeholder="Enter Your Mobile" value={helpDetails.mobile}/>
                                    <small style={{color: 'red'}} className="form-text">{getError('mobile')}</small>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input onChange={(e) => handleInputChange(e, 'email')} type="email" className="form-control" placeholder="Enter Your Email" value={helpDetails.email}/>
                                    <small style={{color: 'red'}} className="form-text">{getError('email')}</small>
                                </div>
                                <div className="form-group">
                                    <label>Describe</label>
                                    <textarea onChange={(e) => handleInputChange(e, 'description')} type="text" className="form-control" placeholder="Describe Your Help" value={helpDetails.description}></textarea>
                                    <small style={{color: 'red'}} className="form-text">{getError('description')}</small>
                                </div>
                                <div className="form-group">
                                    <label>Address</label>
                                    <textarea onChange={(e) => handleInputChange(e, 'address')} type="text" className="form-control" placeholder="Enter Address Where You Nedd Help" value={helpDetails.address}></textarea>
                                    <small style={{color: 'red'}} className="form-text">{getError('address')}</small>
                                </div>
                                <div style={{marginLeft: -11}} className="form-group col-md-6">
                                    <label>Pincode</label>
                                    <input onChange={(e) => handleInputChange(e, 'pincode')} type="number" className="form-control" placeholder="Enter Valid Pincode" value={helpDetails.pincode}/>
                                    <small style={{color: 'red'}} className="form-text">{getError('pincode')}</small>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>State</label>
                                        <select onChange={(e) => onStateChange(e)} id="inputState" className="form-control">
                                            {
                                                allStates.map(state => <option>{state}</option>)
                                            }
                                        </select>
                                        <small style={{color: 'red'}} className="form-text">{getError('state')}</small>   
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>District</label>
                                        <select onChange={(e) => onChangeDist(e)} id="inputState" className="form-control">
                                            {
                                                cities.length === 0 && <option>Choose...</option> 
                                            }
                                            {
                                                 cities.map(city => <option>{city}</option>)
                                            }
                                        </select>
                                        <small style={{color: 'red'}} className="form-text">{getError('district')}</small>  
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>City</label>
                                        <select onChange={(e) => handleInputChange(e, 'city')} id="inputState" className="form-control">
                                            {
                                                cityOfDist.length === 0 && <option>Choose...</option> 
                                            }
                                            {
                                                cityOfDist.length !== 0 && cityOfDist.map(obj => <option>{obj.city}</option>)
                                            }
                                        </select>
                                        <small style={{color: 'red'}} className="form-text">{getError('city')}</small>  
                                    </div>
                                </div> 
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Which Type of Help Needed ?</label>
                                        <select onChange={onHelpTypeChange} id="inputState" className="form-control">
                                            <option> Choose...</option>
                                            <option> Medical </option>
                                            <option>Education</option>
                                            <option> Travel </option>
                                            <option> Dharmik </option>
                                            <option> Legal </option>
                                            <option> Government </option>
                                        </select>
                                        <small style={{color: 'red'}} className="form-text">{getError('typeofhelp')}</small>  
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Categories of Help</label>
                                        <select onChange={(e) => handleInputChange(e, 'subtypeofhelp')} id="inputState" className="form-control">
                                            {
                                                subTypesOfHelp.length === 0 && <option> Choose...</option>
                                            }
                                            {
                                                subTypesOfHelp.length !== 0 && subTypesOfHelp.map(subtype => <option key={subtype}> {subtype}</option>)
                                            }
                                        </select>
                                        <small style={{color: 'red'}} className="form-text">{getError('subtypeofhelp')}</small>  
                                    </div>
                                </div> 
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>How Help is Needed ?</label>
                                        <select onChange={(e) => handleInputChange(e, 'time')} id="inputState" className="form-control">
                                            <option> Choose...</option>
                                            <option> Emergency</option>
                                            <option> Urgent</option>
                                            <option> Normal</option>
                                        </select>
                                        <small style={{color: 'red'}} className="form-text">{getError('time')}</small>  
                                    </div>
                                </div>
                                {
                                    !isLoading &&
                                    <div className="form-group">
                                        <button onClick={handleSubmit} style={{backgroundColor: '#F8C400', color: 'white'}} type="submit" className="btn mx-auto btn-block"> Ask Help  </button>
                                    </div>
                                } 
                                {
                                    isLoading && 
                                    <div style={{marginLeft: '40%'}} className="py-3">
                                        <Loader type="Oval" height={50} color="#F8C400"/>
                                    </div>
                                }        
                            </form>
                        }
                        {
                            !shouldFormDisplay && 
                            <div>
                               
                                <h1 className="text-center">
                                    <img height={120} alt="Successful" src="mark.png" />
                                </h1>
                              
                                <p className="h5 py-3">Our volunteer will be in touch with you shortly</p>
                                <p >Your request is now under processing to check help status please click below button</p>
                                <div className="form-group">
                                    <Link to="/check-help-status" style={{backgroundColor: '#41AD49', color: 'white'}} type="submit" className="btn mx-auto btn-block"> Click Here to Check Status  </Link>
                                </div>
                            </div>
                        }
                </article>
            </div> 
            <br></br><br></br>
        </section>
    )
}

export default HelpForm
