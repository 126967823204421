const HelpCategories = {
    Medical: ['Doctor', 'Hospital', 'Blood', 'Medical Equipment'],
    Education: ['Education'],
    Travel: ['Dharamshala', 'Hotel', 'Train', 'Bus', 'Car'],
    Dharmik: ['Dharmik Book', 'Yagya Hawan', 'Pooja', 'Pandit', 'Others'],
    Legal: ['Legal'],
    Government: ['Government']
}

const getHelpCategories = (type) =>{
    return HelpCategories[type]
}

export default getHelpCategories